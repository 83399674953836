<template>
  <div class="footer">
    <!-- Footer Widgets Start -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="subscribe">

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="foot-logo">
<!--              <a href="index-3.html">-->
<!--                <img src="/images/footer.png" class="img-fluid" alt="footer_logo">-->
<!--              </a>-->
              <div class="address">
                <h3 >{{ contact.title }}</h3>
                <p v-html="contact.content"></p>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="sitemap">
              <h3>কুইক লিংক</h3>
              <ul>
                <li><a href="#">আমাদের বিদ্যাপীঠ</a></li>
                <li><a href="#">আমাদের অ্যাসোসিয়েশন</a></li>
                <li><a href="#">উপদেষ্টা পরিষদ</a></li>
                <li><a href="#">আহবায়ক কমিটি</a></li>
                <li><a href="#">এডমিন মডারেটর প্যানেল</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2">
            <div class="sitemap">
              <h3>গুরুত্বপূর্ণ লিংক</h3>
              <ul>
                <li><a href="#">আমাদের বিদ্যাপীঠ</a></li>
                <li><a href="#">আমাদের অ্যাসোসিয়েশন</a></li>
                <li><a href="#">উপদেষ্টা পরিষদ</a></li>
                <li><a href="#">আহবায়ক কমিটি</a></li>
                <li><a href="#">এডমিন মডারেটর প্যানেল</a></li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div class="sitemap">
              <h3>{{ counter.title }}</h3>
              <div class="" v-html="counter.content"></div>
<!--              <div class="fb-page" style="border: 1px solid #dddddd" data-href="https://www.facebook.com/techintelligence.net" data-tabs="timeline" data-width="" data-height="180" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/techintelligence.net" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/techintelligence.net">Tech Intelligence</a></blockquote></div>-->
            </div>
          </div>
        </div>

      </div>
    </footer>
    <!-- Footer Widgets End -->
    <section class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <p class="mb-0">©২০২২ সর্ব স্বত্ব সংরক্ষিত | Developed By: <a href="https://techintelligencebd.com">Tech Intelligence</a> </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "FooterSection",
  computed:{
    contact(){return this.$store.getters.getContact;},
    counter(){return this.$store.getters.getVisitorCounter;}
  }
}
</script>

<style scoped>
  .address p{
    padding: 1px 0 0;
  }

  footer{
    padding: 30px 0 30px;
  }

  .footer h3{
    padding: 15px 0 0;
  }

  .foot-logo{
    padding: 0 0 0;
  }
</style>