import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import loader from "vue-ui-preloader"

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(loader);


// Vue.http.options.root = 'http://127.0.0.1:8000/api/';
Vue.http.options.root = 'https://admin.rmhkhsaa.com/api/';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')