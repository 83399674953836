import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homePageNotices:[],
    allNotices:[],
    upcomingEvents:[],
    allUpcomingEvents:[],
    pastEvents:[],
    allPastEvents:[],
    advisoryCommittee:[],
    conveningCommittee:[],
    counter:[],
    aboutSchool:[],
    aboutAssociation:[],
    contact:[],
    registrationRules:[],
    visitorCounter:[],
    googleMap:[],
    welcome:[],
    slider:[],
    constitution:[],
    teacher:[],
  },
  getters: {
    getHomePageNotices(state){return state.homePageNotices[0];},
    getAllNotices(state){return state.allNotices[0];},
    getUpcomingEvents(state){return state.upcomingEvents[0];},
    getAllUpcomingEvents(state){return state.allUpcomingEvents[0];},
    getPastEvents(state){return state.pastEvents[0];},
    getAllPastEvents(state){return state.allPastEvents[0];},
    getSingleEvent(state,id){
      var objectIndex = this.upcomingEvents.findIndex(obj => obj.id == id);
      return this.state.pastEvents[objectIndex];
    },
    getEventById: (state) => (id) => {
      if (state.allUpcomingEvents[0]!='undefined'){
        return state.allUpcomingEvents[0].find(sEvent => sEvent.id === id);
      }
    },
    getPastEventById: (state) => (id) => {
      return state.allPastEvents[0].find(sEvent => sEvent.id === id)
    },
    getAdvisoryCommittee(state){return state.advisoryCommittee[0];},
    getConveningCommittee(state){return state.conveningCommittee[0];},
    getCounter(state){return state.counter[0];},
    getAboutSchool(state){return state.aboutSchool[0];},
    getAboutAssociation(state){return state.aboutAssociation[0];},
    getContact(state){return state.contact[0];},
    getRegistrationRules(state){return state.registrationRules[0];},
    getVisitorCounter(state){return state.visitorCounter[0];},
    getGoogleMap(state){return state.googleMap[0];},
    getWelcome(state){return state.welcome[0];},
    getSlider(state){return state.slider[0];},
    getConstitution(state){return state.constitution[0];},
    getTeacher(state){return state.teacher[0];},
  },
  mutations: {
    addHomePageNotices(state,notices){state.homePageNotices.push(notices);},
    addAllNotices(state,notices){state.allNotices.push(notices);},
    addUpcomingEvents(state,events){state.upcomingEvents.push(events);},
    addAllUpcomingEvents(state,events){state.allUpcomingEvents.push(events);},
    addPastEvents(state,events){state.pastEvents.push(events);},
    addAllPastEvents(state,events){state.allPastEvents.push(events);},
    addAdvisoryCommittee(state,events){state.advisoryCommittee.push(events);},
    addConveningCommittee(state,events){state.conveningCommittee.push(events);},
    addCounter(state,values){state.counter.push(values);},
    addAboutSchool(state,values){state.aboutSchool.push(values);},
    addAboutAssociation(state,values){state.aboutAssociation.push(values);},
    addContact(state,values){state.contact.push(values);},
    addRegistrationRules(state,values){state.registrationRules.push(values);},
    addVisitorCounter(state,values){state.visitorCounter.push(values);},
    addGoogleMap(state,values){state.googleMap.push(values);},
    addWelcome(state,values){state.googleMap.push(values);},
    addSlider(state,values){state.slider.push(values);},
    addConstitution(state,values){state.constitution.push(values);},
    addTeacher(state,values){state.teacher.push(values);},
  },
  actions: {
  },
  modules: {
  }
})
