<template>
  <!--Menu area start-->
  <div class="myMenu">
    <Preloader v-if="!loaded" />
<!--  <div data-toggle="affix" class="myMenu">-->
    <div class="container nav-menu2">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar2 navbar-toggleable-md navbar-light bg-faded" :key="$route.path" >
            <button class="navbar-toggler navbar-toggler2 navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown">
              <span class="icon-menu"></span>
            </button>
            <router-link :to="{name: 'home'}" class="navbar-brand nav-brand2">

              <h2>
                <img src="/images/logos.png" class="img-fluid logo" alt="logo">
              </h2>
            </router-link>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
              <ul class="navbar-nav">
                <li class="nav-item">

                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'home'}" class="nav-link">হোম</router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'constitution'}" class="nav-link" >গঠনতন্ত্র</router-link>
                </li>

                <li class="nav-item dropdown">
                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                    আমাদের সম্পর্কে<span class="glyphicon glyphicon-chevron-down pull-right"></span>
                  </a>
                  <ul class="dropdown-menu">
                    <li><router-link :to="{name: 'aboutAlumni'}" class="dropdown-item" >আমাদের অ্যাসোসিয়েশন</router-link></li>
                    <li><router-link :to="{name: 'aboutSchool'}" class="dropdown-item">আমাদের বিদ্যাপীঠ</router-link></li>

                  </ul>
                </li>

                <li class="js-navbar-collapse">
                  <ul class="nav navbar-nav">
                    <li class="dropdown mega-dropdown">
                      <a href="academics.html" class="dropdown-toggle nav-link" data-toggle="dropdown">ইভেন্ট <span class="glyphicon glyphicon-chevron-down pull-right"></span></a>
                      <ul class="dropdown-menu mega-dropdown-menu row" style="top: 51px">
                        <li class="col-lg-4">
                          <img src="/images/courses_41.jpg" class="mega-img img-fluid dropdown-header" alt="#">
                        </li>
                        <li class="col-lg-4">
                          <ul>
                            <li class="dropdown-header">সদ্য সমাপ্ত ইভেন্টস</li>
                            <li v-for="(event,key) in pastEvents" :key="key">
                              <router-link :to="{name: 'pastEventDetails',params:{id:event.id}}" >{{event.title}}</router-link>
                            </li>
                            <li class="divider"></li>
                          </ul>
                        </li>
                        <li class="col-lg-4">
                          <ul>
                            <li class="dropdown-header">আসন্ন ইভেন্ট</li>
                            <li v-for="(event,key) in upcomingEvents" :key="key">
                              <router-link :to="{name: 'eventDetails',params:{id:event.id}}" >{{event.title}}</router-link>
                            </li>
                            <li class="divider"></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li class="nav-item dropdown">
                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                    কমিটি<span class="glyphicon glyphicon-chevron-down pull-right"></span>
                  </a>
                  <ul class="dropdown-menu">
                    <li><router-link :to="{name: 'advisoryCommitee'}" class="dropdown-item">উপদেষ্টা পরিষদ</router-link></li>
                    <li><router-link :to="{name: 'alumniCommitee'}" class="dropdown-item">আহবায়ক কমিটি</router-link></li>
<!--                    <li><router-link :to="{name: 'adminPanel'}" class="dropdown-item" >এডমিন মডারেটর প্যানেল</router-link></li>-->
                  </ul>
                </li>

                <li class="nav-item dropdown">
                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                    ঈদ পুনর্মিলনী<span class="glyphicon glyphicon-chevron-down pull-right"></span>
                  </a>
                  <ul class="dropdown-menu">
                    <li><router-link :to="{name: 'eidReunionRegistration'}" class="dropdown-item">নিবন্ধন</router-link></li>
                    <li><router-link :to="{name: 'eidReunionMemberLogin'}" class="dropdown-item" >লগইন</router-link></li>
                  </ul>
                </li>

<!--                <li class="nav-item dropdown">-->
<!--                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">-->
<!--                    নিবন্ধন<span class="glyphicon glyphicon-chevron-down pull-right"></span>-->
<!--                  </a>-->
<!--                  <ul class="dropdown-menu">-->
<!--                    <li><router-link :to="{name: 'registration'}" v-if="!loginStatus" class="dropdown-item">সাধারণ নিবন্ধন</router-link></li>-->
<!--                    <li><router-link :to="{name: 'eidReunionRegistration'}" class="dropdown-item">ঈদ পুনর্মিলনী নিবন্ধন</router-link></li>-->
<!--&lt;!&ndash;                    <li><router-link :to="{name: 'adminPanel'}" class="dropdown-item" >এডমিন মডারেটর প্যানেল</router-link></li>&ndash;&gt;-->
<!--                  </ul>-->
<!--                </li>-->


                <li class="nav-item">
                  <router-link :to="{name: 'registration'}" v-if="!loginStatus" class="nav-link">নিবন্ধন</router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'contactUs'}" class="nav-link">যোগাযোগ</router-link>
                </li>


                <li class="nav-item dropdown">
                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                    অন্যান্য<span class="glyphicon glyphicon-chevron-down pull-right"></span>
                  </a>

                  <ul class="dropdown-menu">
<!--                    <li><router-link :to="{name: 'photoGallery'}" class="dropdown-item">ফটো গ্যালারী</router-link></li>-->
                    <li><router-link :to="{name: 'noticeboard'}" class="dropdown-item">নোটিশ</router-link></li>
                    <li><router-link :to="{name: 'registrationRules'}" class="dropdown-item">নিবন্ধের নিয়মাবলি</router-link></li>
                  </ul>
                </li>

                <li class="nav-item dropdown" v-if="loginStatus">
                  <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                    {{ memberInfo.name }}<span class="glyphicon glyphicon-chevron-down pull-right"></span>
                  </a>

                  <ul class="dropdown-menu">
                    <li><router-link :to="{name: 'viewProfile'}" class="dropdown-item"><i class="fa fa-user"></i> প্রোফাইল</router-link></li>
                    <li><button @click="logout" class="dropdown-item"><i class="fa fa-sign-out"></i> লগআউট</button></li>
                  </ul>
                </li>

                <li class="nav-item btn" v-if="!loginStatus" style="padding: 1px 4px; background-color: #1D2878">
                  <router-link :to="{name: 'loginForm'}" class="nav-link text-white" style="font-family: 'SolaimanLipi', sans-serif !important;padding: 1px 10px"><i class="fa fa-user"></i> লগইন</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- Menu area end -->

</template>

<script>
import Preloader from "@/components/Preloader";
export default {
  name: "HeaderSection",
  components:{
    Preloader
  },
  data(){
    return {
      loginStatus:false,
      memberInfo:null,
      loaded:true
    }
  },
  created() {
    this.memberInfo = JSON.parse(localStorage.getItem('member'));
    //Redirect to login form
    if (this.memberInfo !== null){
      this.loginStatus = true
    }else {
      this.loginStatus = false;
    }
  },
  computed:{
    pastEvents(){return this.$store.getters.getAllPastEvents;},
    upcomingEvents(){return this.$store.getters.getAllUpcomingEvents;}
  },
  methods:{
    logout(){
      this.loaded = false;
      this.$http.post('member-logout', {id:this.memberInfo.id}).then(response => {
        if (response.body.status=='success'){
          localStorage.removeItem('member');
          localStorage.removeItem('avatar');
          this.$router.push({name:'home'});
        }
      });
    },
  }
}
</script>

<style scoped>
.myMenu{
  background-color: #ffffff;
  position: sticky;
  top: 0;
  width: 100%;
  float: left;
  height: 112px;
  margin-bottom: 10px;
  z-index: 999999;
}
.nav-menu2{
  background-color: #ffffff;
}

nav.navbar.fixed-top {
  max-height: 70%;
  overflow-y: auto;
}
.nav-menu2 .navbar-light .navbar-nav .nav-link{
  font-size: 18px;
}

@media(max-width: 991px) {

  .navbar-brand{
    padding-top: 10px;
    height: 85px;
    width: 70px;
  }
  .navbar-collapse {
    max-height: calc(100vh - 110px); overflow-y: auto;
  }

  .navbar-toggleable-md .navbar-nav .dropdown-menu{
    padding-top: 0;
  }
  .mega-dropdown-menu>li{
    text-align: center;
    width: 60%;
  }
  .mega-img {
    display: none;
  }
  .navbar-nav{
    height: 60%;
    overflow-y: visible;
  }

  .navbar-light .navbar-toggler2{
    margin: 18px 0!important
  }
}
</style>