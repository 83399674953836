<template id="preloader">
  <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="49" disableScrolling="false" name="dots"></loader>
</template>

<script>
export default {
  name: "CustomPreloader"
}
</script>

<style scoped>

</style>