import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/constitution',
    name: 'constitution',
    component: () => import(/* webpackChunkName: "constitution" */ '../views/ConstitutionView.vue')
  },
  {
    path: '/about-alumni',
    name: 'aboutAlumni',
    component: () => import(/* webpackChunkName: "aboutAlumni" */ '../views/AboutAlumni.vue')
  },
  {
    path: '/about-school',
    name: 'aboutSchool',
    component: () => import(/* webpackChunkName: "aboutSchool" */ '../views/AboutSchool.vue')
  },
  {
    path: '/event-details/:id',
    name: 'eventDetails',
    props: true,
    component: () => import(/* webpackChunkName: "eventDetails1" */ '../views/EventDetails.vue')
  },
  {
    path: '/past-event-details/:id',
    name: 'pastEventDetails',
    props: true,
    component: () => import(/* webpackChunkName: "pastEventDetails" */ '../views/PastEventDetails.vue')
  },
  {
    path: '/upcoming-event1',
    name: 'upcomingEvent1',
    component: () => import(/* webpackChunkName: "upcomingEvent1" */ '../views/UpcomingEvent1.vue')
  },
  {
    path: '/advisory-commitee',
    name: 'advisoryCommitee',
    component: () => import(/* webpackChunkName: "advisoryCommitee" */ '../views/AdvisoryCommitee.vue')
  },
  {
    path: '/alumni-commitee',
    name: 'alumniCommitee',
    component: () => import(/* webpackChunkName: "alumniCommitee" */ '../views/AlumniCommitee.vue')
  },
  {
    path: '/admin-panel',
    name: 'adminPanel',
    component: () => import(/* webpackChunkName: "adminPanel" */ '../views/AdminModPanel.vue')
  },
  {
    path: '/registration-view',
    name: 'registration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/RegistrationView.vue')
  },
  {
    path: '/eid-reunion-registration',
    name: 'eidReunionRegistration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/EidReunionRegistrationClose.vue')
  },
  {
    path: '/eid-reunion-member-login',
    name: 'eidReunionMemberLogin',
    component: () => import(/* webpackChunkName: "registration" */ '../views/EidReunionMemberLoginForm.vue')
  },
  {
    path: '/eid-reunion-member-profile',
    name: 'eidReunionMemberProfile',
    component: () => import(/* webpackChunkName: "registration" */ '../views/EidReunionMemberProfile.vue')
  },
  {
    path: '/photo-gallery',
    name: 'photoGallery',
    component: () => import(/* webpackChunkName: "photoGallery" */ '../views/PhotoGallery.vue')
  },
  {
    path: '/noticeboard',
    name: 'noticeboard',
    component: () => import(/* webpackChunkName: "noticeboard" */ '../views/NoticeboardView.vue')
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ '../views/ContactView.vue')
  },
  {
    path: '/all-upcoming-events',
    name: 'allUpcomingEvents',
    component: () => import(/* webpackChunkName: "allUpcomingEvents" */ '../views/AllUpcomingEvent.vue')
  },
  {
    path: '/all-recent-events',
    name: 'allRecentEvents',
    component: () => import(/* webpackChunkName: "allRecentEvents" */ '../views/AllRecentEvent.vue')
  },
  {
    path: '/login-form',
    name: 'loginForm',
    component: () => import(/* webpackChunkName: "loginForm" */ '../views/LoginForm.vue')
  },
  {
    path: '/view-profile',
    name: 'viewProfile',
    component: () => import(/* webpackChunkName: "viewProfile" */ '../views/ProfileView.vue')
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/EditProfile.vue')
  },
  {
    path: '/registration-rules',
    name: 'registrationRules',
    component: () => import(/* webpackChunkName: "registrationRules" */ '../views/RegistrationRules.vue')
  }
]

const router = new VueRouter({
  // mode: '#',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
