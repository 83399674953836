<template>
  <div id="app" :key="$route.path">
    <HeaderSection/>
<!--    <HeaderSection :key="Math.random()"/>-->
    <router-view/>
    <FooterSection/>
  </div>
</template>

<script>

  import HeaderSection from "@/components/includes/HeaderSection";
  import FooterSection from "@/components/includes/FooterSection";
  export default {
    name: "App",
    components:{
      HeaderSection,
      FooterSection,
    },
    // beforeCreate() {
    //
    // },
    beforeCreate() {
      this.$http.get('slider').then(response=>{
        this.$store.state.slider.push(response.body);
      });
      this.$http.get('upcoming-events').then(response=>{
        this.$store.state.upcomingEvents.push(response.body);
      });
      this.$http.get('all-upcoming-events').then(response=>{
        this.$store.state.allUpcomingEvents.push(response.body);
      });
      this.$http.get('past-events').then(response=>{
        this.$store.state.pastEvents.push(response.body);
      });
      this.$http.get('all-past-events').then(response=>{
        this.$store.state.allPastEvents.push(response.body);
      });
      this.$http.get('home-page-notices').then(response=>{
        this.$store.state.homePageNotices.push(response.body);
      });
      this.$http.get('all-notices').then(response=>{
        this.$store.state.allNotices.push(response.body);
      });
      this.$http.get('advisory-committee').then(response=>{
        this.$store.state.advisoryCommittee.push(response.body);
      });
      this.$http.get('convening-committee').then(response=>{
        this.$store.state.conveningCommittee.push(response.body);
      });
      this.$http.get('counter').then(response=>{
        this.$store.state.counter.push(response.body);
      });
      this.$http.get('about-school').then(response=>{
        this.$store.state.aboutSchool.push(response.body);
      });
      this.$http.get('about-association').then(response=>{
        this.$store.state.aboutAssociation.push(response.body);
      });
      this.$http.get('contact').then(response=>{
        this.$store.state.contact.push(response.body);
      });
      this.$http.get('registration-rules').then(response=>{
        this.$store.state.registrationRules.push(response.body);
      });
      this.$http.get('visitor-counter').then(response=>{
        this.$store.state.visitorCounter.push(response.body);
      });
      this.$http.get('google-map').then(response=>{
        this.$store.state.googleMap.push(response.body);
      });
      this.$http.get('welcome').then(response=>{
        this.$store.state.welcome.push(response.body);
      });
      this.$http.get('constitution').then(response=>{
        this.$store.state.constitution.push(response.body);
      });
      this.$http.get('teacher').then(response=>{
        this.$store.state.teacher.push(response.body);
      });
    },
  }
</script>

<style scoped>

</style>
